import React, { useState } from 'react';

function Step3( props ) {

    const [personalData, setPersonalData] = useState();

    const handleChange = e => {
        const { name, value } = e.target;
        setPersonalData({ ...personalData, [name]: value });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        props.setPersonalData(personalData);
        await props.setPage(3);
    }

    return (
        <form onSubmit={handleSubmit} name="myAppoinment">
            <h3>Completa la Reserva</h3>
            <div className="form-group py-1">
                <span htmlFor="name">Nombre Completo</span>
                <input
                    type="text"
                    className="form-control"
                    placeholder=" "
                    name="name"
                    required
                    onChange={handleChange}
                />
            </div>
            <div className="form-group py-1">
                <span htmlFor="email">Correo Electrónico</span>
                <input
                    type="email"
                    className="form-control"
                    placeholder=" "
                    name="email"
                    required
                    onChange={handleChange}
                />
            </div>
            <div className="form-group py-1">
                <span htmlFor="phone">Número Telefónico</span>
                <input
                    type="tel"
                    className="form-control"
                    placeholder=" "
                    name="phone"
                    required
                    onChange={handleChange}
                />
            </div>
            <div className="form-group py-1">
                <input
                    id="terms"
                    type="checkbox"
                    className="form-check-input"
                    name="terms"
                    required
                    onChange={handleChange}
                />
                <span className="form-check-label ps-2" for="terms">Acepto las <a target="_blank" href="https://myappointment.dev/legal/terms">Condiciones del servicio</a> y la <a target="_blank" href="https://myappointment.dev/legal/privacy">Politica de Privacidad</a> de My Appointment</span>
            </div>
            <button className="btn btn-primary btn-block my-3" type="submit">Reservar</button>
        </form>
    );
};

export default Step3;