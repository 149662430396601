import React from "react";
import Preloader from '../../../hooks/preloader'
import GetDataFromId from '../../../hooks/getDataFromId'
import Form from './form'
import './styles.css'
import logo from './images/logo.svg'
import background from './images/bg-principal.jpg'

const places = {
    Bogota: [
        {uid: '', name: "Selecciona la sede"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Artesano 109"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Artesano 81"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Artesano Rosales"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Avenida 19"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Avenida Esperanza"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Barra Unicentro"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Calle 73"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Cedritos"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Centro Comercial Atlantis Plaza"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Centro Comercial Centro Chía"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Centro Comercial Centro Mayor"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Centro Comercial Gran Estación Alfiles"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Centro Comercial Gran Estación Esfera"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Centro Comercial Plaza 39"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Centro Comercial Avenida Chile"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Centro Comercial Bazaar"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Centro Comercial Bima"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Centro Comercial Bulevar Niza"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Centro Comercial Calima"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Centro Comercial Diverplaza"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Centro Comercial El Edén "},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Centro Comercial Floresta"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Centro Comercial Fontanar"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Centro Comercial Hayuelos"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Centro Comercial Metrópolis"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Centro Comercial Palatino"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Centro Comercial Parque de la Colina"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Centro Comercial Paseo Villa del Río"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Centro Comercial Plaza Central"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Centro Comercial Plaza de las Américas"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Centro Comercial Plaza Imperial"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Centro Comercial Portal 80"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Centro Comercial Salitre Plaza"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Centro Comercial San Martín"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Centro Comercial San Rafael"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Centro Comercial Santa Ana"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Centro Comercial Santafé",},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Centro Comercial Titan",},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Centro Comercial Unicentro"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Centro Comercial Ventura Terreros"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Centro Comercial Zuca Plaza"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Centro Internacional"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Connecta"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Galerías"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "La Felicidad"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Las Aguas"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "MAMBO"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Parkway"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Parque 93"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Sheraton"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Torre 140"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Usaquén"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "World Trade Center"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Zona T Calle 83"}
    ],
    Barranquilla: [
        {uid: '', name: "Selecciona la sede"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Alto Prado"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Centro Comercial  Buenavista"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Centro Comercial Portal del Prado"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Viva Barranquilla"}
    ],
    Medellin: [
        {uid: '', name: "Selecciona la sede"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Arkadia"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Campestre"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Centro Comercial Premium Plaza"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Centro Comercial Unicentro"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "El Poblado"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "El Tesoro"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Florida"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Laureles"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Llano Grande"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Mayorca"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Mayorca Mega Plaza"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Molinos"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Museo de Arte"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "One Plaza"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Oviedo"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Palma Grande"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Puerta Del Norte"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "San Diego"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "San Nicolás"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Santa fé"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Viva Envigado"}
    ],
    Bucaramanga: [
        {uid: '', name: "Selecciona la sede"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Centro Comercial Cacique"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Centro Comercial La Florida"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Centro Comercial Mega Mall"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "San Pío"}
    ],
    Cali: [
        {uid: '', name: "Selecciona la sede"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Avenida Colombia"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Centro Comercial Chipichape"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Centro Comercial Jardín Plaza"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Centro Comercial Palmetto"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Centro Comercial Unicentro"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Ciudad Jardín"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Flora"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Lyrata Plaza"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Roosevelt"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Santa Mónica"}
    ],
    Cartagena: [
        {uid: '', name: "Selecciona la sede"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "CC La Serrezuela"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Centro Comercial Caribe Plaza"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Centro Comercial Mall Plaza El Castillo"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Plaza Boca Grande"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "San Martín"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "San Pedro"}
    ],
    Pereira: [
        {uid: '', name: "Selecciona la sede"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Centro Comercial Parque Arboleda"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Centro Comercial Victoria"}
    ],
    "Santa Marta": [
        {uid: '', name: "Selecciona la sede"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Centro Comercial Zazué Plaza"}
    ],
    Villavicencio: [
        {uid: '', name: "Selecciona la sede"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Centro Comercial Primavera Urbana"}
    ],
    Manizales: [
        {uid: '', name: "Selecciona la sede"},
        {uid: '3L7QSrCzOXROuIXDo8VtZ0w38Fu2', name: "Mall Plaza"}
    ]
}


function PageCrepesWaffles() {
    const id = '3L7QSrCzOXROuIXDo8VtZ0w38Fu2'
    const item = GetDataFromId(id)

    if (item === null) {
        return <Preloader/>;
    }

    document.title = `Reservas | ${item.restaurantName}`;

    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-dark page-header">
                <div className=" navbar-collapse justify-content-center">
                    <div className="col-10  col-sm-8  col-md-6 col-lg-4 text-center " id="logo">
                        <a href="https://crepesywaffles.com/" title="Inicio" >
                            <img src={logo} alt="Inicio"/>
                        </a>
                    </div>
                </div>
            </nav>
            <main className="mb-1 bg-reservas" style={{backgroundImage: `url("${background}")`}}>
                <section className="d-flex justify-content-center vh-100">
                    <div className="align-self-center text-center container" id="title">
                        <div className="row py-3 py-sm-4 py-md-5">
                            <div className="col-lg-6 col-md-8 mx-auto">
                                <h1>Reservas</h1>
                                <p className="lead text-center">Encuentra el más cercano</p>
                            </div>
                        </div>
                        <div className="row py-3 py-sm-4 py-md-5">
                            <div className="col-lg-6 col-md-8 mx-auto">
                                <Form {...{item, places}}/>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
}
export default PageCrepesWaffles;