import React from 'react';
import GoogleMapReact from 'google-map-react';
import Marker from '../../../components/Marker';
const API_KEY = 'AIzaSyCrGOlT7ET4qEHutTwWG_D7O9nFormWP0w'

const places = [
    { id: 1, title: "Oeste", lat: 3.406456, lng: -76.5577676 },
    { id: 2, title: "Norte", lat: 3.4710562, lng: -76.5634823 },
    { id: 3, title: "Sur", lat: 3.3645748, lng: -76.5692004 }
];

const getMapBounds = (map, maps, places) => {
    const bounds = new maps.LatLngBounds();

    places.forEach((place) => {
        bounds.extend(new maps.LatLng(
            place.lat,
            place.lng,
        ));
    });
    return bounds;
};

// Re-center map when resizing the window
const bindResizeListener = (map, maps, bounds) => {
    maps.event.addDomListenerOnce(map, 'idle', () => {
        maps.event.addDomListener(window, 'resize', () => {
            map.fitBounds(bounds);
        });
    });
};

// Fit map to its bounds after the api is loaded
const apiIsLoaded = (map, maps, places) => {
    // Get bounds by our places
    const bounds = getMapBounds(map, maps, places);
    // Fit map to bounds
    map.fitBounds(bounds);
    // Bind the resize listener
    bindResizeListener(map, maps, bounds);
};


function GoogleMap(props) {
    const latitude = Number(3.4062859);
    const longitude = Number(-76.5577675);

    return (
        <div style={{ height: '50vh', width: '100%' }}>
            <GoogleMapReact
                defaultZoom={12}
                defaultCenter={{ lat: latitude, lng: longitude }}
                bootstrapURLKeys={{ key: `${API_KEY}` }}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps, places)}
            >
                {places.map( (place) => (
                    <Marker
                        key={place.id}
                        text={place.title}
                        lat={place.lat}
                        lng={place.lng}
                    />
                ) )}
            </GoogleMapReact>
        </div>
    );
}

export default GoogleMap;
