import React from 'react';
import {Grid, Typography, Box, Container, Link, Avatar, Dialog} from '@material-ui/core';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import FacebookIcon from '@material-ui/icons/Facebook';
import Logo from './logo'
import {useStyles} from '../../assets/css/useStyles'

export default function Footer() {
    const classes = useStyles();
    const [openForm, setOpenForm] = React.useState(false);

    const formClick = (event) => {
        event.preventDefault();
        setOpenForm(true);
    };

    const linkNone = (event) => {
        event.preventDefault();
    };

    return (
        <footer>
            <footer className={classes.separator}/>
            <Container maxWidth="lg" component="footer" className={classes.footer}>
                <Grid container spacing={5} direction="row" justify="center" alignItems="center">
                    <Grid item xs={12} sm={4}>
                        <Box>
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <Typography variant="h6" color="textPrimary" gutterBottom>
                                    <Logo/>
                                </Typography>
                            </Box>
                            <Box display="flex" justifyContent="center" alignItems="center">

                                <Link href="#" className={classes.link} underline="none" variant="inherit" color="textPrimary" onClick={formClick}>
                                    Contact
                                </Link>
                                <Dialog open={openForm} onClose={() => setOpenForm(false)} aria-labelledby="contact-form">

                                </Dialog>
                                <div className={classes.divider}/>
                                <Link href="/legal/privacy" className={classes.link} underline="none" variant="inherit" color="textPrimary" >
                                    Privacy
                                </Link>
                                <div className={classes.divider}/>
                                <Link href="/legal/terms" className={classes.link} underline="none" variant="inherit" color="textPrimary">
                                    Terms
                                </Link>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Box>
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <Typography variant="inherit" color="textPrimary" gutterBottom>
                                    MyAppointment® {new Date().getFullYear()}
                                </Typography>
                            </Box>
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <Typography variant="inherit" color="textPrimary" gutterBottom>
                                    <Link className={classes.link} underline="none" variant="inherit" color="textPrimary" href={`mailto:demo@myappointment.dev`}>
                                        demo@myappointment.dev
                                    </Link>
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Box>
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <Typography variant="inherit" color="textPrimary" gutterBottom>
                                    Follow us on:
                                </Typography>
                            </Box>
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <Link href="#" onClick={linkNone}>
                                    <Avatar className={classes.avatar}>
                                        <TwitterIcon/>
                                    </Avatar>
                                </Link>
                                <span className={classes.spanFooter}/>
                                <Link href="#" onClick={linkNone}>
                                    <Avatar className={classes.avatar}>
                                        <LinkedInIcon/>
                                    </Avatar>
                                </Link>
                                <span className={classes.spanFooter}/>
                                <Link href="#" onClick={linkNone}>
                                    <Avatar className={classes.avatar}>
                                        <FacebookIcon/>
                                    </Avatar>
                                </Link>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </footer>
    );
}