import React from 'react';
import GoogleMapReact from 'google-map-react';
const API_KEY = 'AIzaSyCrGOlT7ET4qEHutTwWG_D7O9nFormWP0w'

function GoogleMap({ item }) {
    const latitude = Number(item.latitude);
    const longitude = Number(item.longitude);

    const renderMarkers = (map, maps) => {
        let marker = new maps.Marker({
            position: { lat: latitude, lng: longitude },
            map,
            title: 'Ubicacion'
        });
        return marker;
    };

    return (
        <div style={{ height: '50vh', width: '100%' }}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: `${API_KEY}` }}
                defaultCenter={{ lat: latitude, lng: longitude }}
                defaultZoom={16}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
            >
            </GoogleMapReact>
        </div>
    );
}

export default GoogleMap;
