import React, { useState } from 'react';

function Step2( props )  {
    const [hour, setHour] = useState();

    const handleChange = e => {
        const { name, value } = e.target;
        setHour({ ...hour, [name]: value });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        props.setHour(hour);
        if (props.button === 'Siguiente'){
            await props.setPage(2);
        } else {
            await props.setPage(0);
        }
    };

    return (
        <div className="container">
            <h3>Seleccione la Hora Disponible</h3>
            <form className="row" onSubmit={handleSubmit} name="myAppoinment">
                {props.button === 'Siguiente' ?
                    props.times.map( time => (
                        <div className="form-check col-4 py-2" key={time}>
                            <input type="radio" className="btn-check" name="time" id={time} autoComplete="off"
                                   value={time} onClick={() => setHour(time)} required onChange={handleChange}/>
                            <label className="btn btn-outline-primary" htmlFor={time}>{time}</label>
                        </div>
                    ))
                    : <div className="form-check">
                        <h3>No Hay mesas disponibles</h3>
                    </div>
                }
                <button className="btn btn-primary btn-block my-3" type="submit">{props.button}</button>
            </form>
        </div>
    );
}

export default Step2;
