import React, {useEffect} from "react";

function ThankYou(props) {

    const sendFormData = () => props.sendFormData()

    useEffect( () => {
        sendFormData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [] )


    return (
        <React.Fragment>
            <div className='container'>
                <div className=''>
                    <div className='container' >
                        <div className='d-flex justify-content-center'>

                        </div>
                        <h2 className='text-center text-primary '>
                            ¡gracias!
                        </h2>
                        <h5 className='text-center text-secondary'>
                            Tu reserva está completa. te veremos pronto.
                        </h5>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default ThankYou;