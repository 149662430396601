import React from "react";
import Header from '../common/header';
import Footer from "../common/footer";
import Preloader from '../../hooks/preloader'
import GetDataFromMatch from '../../hooks/getDataFromMatch'
import RestaurantName from "../../components/restaurantname";
import ItemDetails from "../../components/details";
import GoogleMap from '../../components/map'
import Form from '../form/index'

function IndexPage(props) {
    const subdomain = props.match.params.subdomain;
    const item = GetDataFromMatch(subdomain)

    if (item === null) {
        return <Preloader/>;
    }

    document.title = `Reservas | ${item.restaurantName}`;

    return (
        <>
            <Header/>
            <main className="main mb-1">
                {item.profileCoverPhoto === "" ? "" :
                    <div className="jumbotron text-center bg_top"
                         style={{backgroundImage: `url("${item.profileCoverPhoto}")`}}>
                        <div className="container">
                        </div>
                    </div>}
                <div className="container">
                    <div className="row row-cols-4">
                        <div className="col-12 mb-1 mb-sm-4 container-distance">
                            <div className="d-flex flex-row align-items-center mt-n64">
                                <RestaurantName {...{item}}/>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-5 order-md-2">
                            <div className="card mb-4 p-3 box-shadow">
                                <Form {...{item}}/>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-7 order-md-1">
                            <div className="card flex-md-row mb-4 box-shadow">
                                <ItemDetails {...{item}}/>
                            </div>
                        </div>
                    </div>
                    {item.latitude === 1 ? "" :
                        <div className="pt-0 pb-3">
                            <GoogleMap {...{item}}/>
                        </div>}
                </div>
            </main>
            <Footer/>
        </>
    );
}

export default IndexPage;