import { useState, useEffect } from "react";
import {db} from "../services/firebase";

const GetDataFromMatch = (subdomain) => {
    const [item, setItem] = useState( null );
    useEffect( () => {
        (async () => {
            const itemRef = db.collection( 'merchant_data' );
            const item = await itemRef.where( 'subdomain', '==', subdomain ).get();
            if (item.empty) {
                console.log( 'No matching documents.' )
                setTimeout (() => window.location.href = 'https://myappointment.dev/', 2000);
                return;
            }
            item.forEach( doc => {
                const data = []
                data.push( {...doc.data(), id: doc.id} )
                setItem( data[0] );
            } );
        })();
    }, [subdomain] );
    return item
};
export default GetDataFromMatch;