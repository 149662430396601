import React from "react";
import Preloader from '../../../hooks/preloader'
import GetDataFromId from '../../../hooks/getDataFromId'
import GoogleMap from './map'
import Form from './form'
import background from './images/Diseño-web-Tortelli-11.jpg'
import Oeste from './images/Peñón.jpg'
import Norte from './images/Santa-mónica.png'
import Sur from './images/Palmas-mall.jpg'

const places = {
    oeste:
        {id: 1, title: "Oeste", lat: 3.406456, lng: -76.5577676},
    norte:
        {id: 2, title: "Norte", lat: 3.4710562, lng: -76.5634823},
    sur:
        {id: 3, title: "Sur", lat: 3.3645748, lng: -76.5692004}
}


function PageTortelli() {
    const id = '3L7QSrCzOXROuIXDo8VtZ0w38Fu2'
    const item = GetDataFromId(id)

    if (item === null) {
        return <Preloader/>;
    }

    document.title = `Reservas | ${item.restaurantName}`;

    return (
        <>
            <main className="mb-1"
                  style={{backgroundImage: `url("${background}")`}}>
                <section className="py-5 text-center container">
                    <div className="row py-lg-5">
                        <div className="col-lg-6 col-md-8 mx-auto">
                            <h1 className="fw-light">Reservas</h1>
                            <hr className="featurette-divider"/>
                        </div>
                    </div>
                </section>
                <section className="py-5 container">
                    <div className="container marketing">
                        <div className="row">
                            <div className="col-lg-4">
                                <img
                                    src={Oeste}
                                    alt="Tortelli Oeste" width="330"/>
                                <h2>Oeste</h2>
                                <p>Dirección:
                                    <a href={`https://www.google.com/maps/dir/?api=1&destination=${places.oeste.lng},${places.oeste.lat}&travelmode=walking`}
                                       target="_blank" rel="noreferrer" className="mx-4 text-title">
                                        <br/> Calle 3 Oeste # 3 – 15<br/>
                                    </a>
                                    <strong>El Peñón</strong>
                                </p>
                            </div>
                            <div className="col-lg-4">
                                <img
                                    src={Norte}
                                    alt="Tortelli Norte" width="330"/>
                                <h2>Norte</h2>
                                <p>Dirección:
                                    <a href={`https://www.google.com/maps/dir/?api=1&destination=${places.norte.lng},${places.norte.lat}&travelmode=walking`}
                                       target="_blank" rel="noreferrer" className="mx-4 text-title">
                                        <br/> Calle 29 Norte # 6 Bis – 67 Local 7 <br/>
                                    </a>
                                    <strong>Santa Mónica</strong>
                                </p>
                            </div>
                            <div className="col-lg-4">
                                <img
                                    src={Sur}
                                    alt="Tortelli Sur" width="330"/>
                                <h2>Sur</h2>
                                <p>Dirección:
                                    <a href={`https://www.google.com/maps/dir/?api=1&destination=${places.sur.lng},${places.sur.lat}&travelmode=walking`}
                                       target="_blank" rel="noreferrer" className="mx-4 text-title">
                                        <br/>Av. San Joaquín&nbsp;# 15 – 09<br/>
                                    </a>
                                    <strong>Palmas Mall</strong>
                                </p>
                            </div>
                            <p className="lead text-muted text-center">*Tener en cuenta que las reservas se toman hasta
                                las 8:00 pm</p>
                        </div>
                    </div>
                </section>
                <section className="py-5 text-center container">
                    <div className="row py-lg-5">
                        <div className="col-lg-6 col-md-8 mx-auto">
                            <Form {...{item}}/>
                        </div>
                    </div>
                </section>
                <section className="py-5">
                    <div className="pt-0 pb-3">
                        <GoogleMap {...{item}}/>
                    </div>
                </section>
            </main>
        </>
    );
}
export default PageTortelli;