import React from "react";

function RestaurantName({item} ) {

    return (
        <>
            <div className="col">
                {item.profilePhoto === "" ? "" :
                <div className="logo">
                    <img className="bg-light rounded-circle border box-shadow w-100 mt-logo"
                         src={item.profilePhoto}
                         alt={`Logo of ${item.restaurantName}`}/>
                </div>}
            </div>
            <div className="col col-9 mt-64">
                {item.restaurantName === "" ? "" :
                    <h2 className="mx-4 text-title">{item.restaurantName}</h2>
                }
                {item.street_address === "" ? "" :
                    <a href={`https://www.google.com/maps/dir/?api=1&destination=${item.latitude},${item.longitude}&travelmode=walking`} target="_blank" rel="noreferrer" className="mx-4 text-title">{item.street_address} {item.locality}, {item.region} {item.postal_code} {item.country}</a>
                }
            </div>
        </>
    );
}

export default RestaurantName;