import React from "react";
import Header from '../../common/header';
import Footer from "../../common/footer";
import Preloader from '../../../hooks/preloader'
import GetDataFromId from '../../../hooks/getDataFromId'
import RestaurantName from "../../../components/restaurantname";
import ItemDetails from "../../../components/details";
import GoogleMap from '../../../components/map'
import Form from './form'

function PageBridadosBogota() {
    const id = 'XSh7MgDaFxNIsKX1sEMTMkQTwTy2'
    const item = GetDataFromId(id)

    if (item === null) {
        return <Preloader/>;
    }

    document.title = `Reservas | ${item.restaurantName}`;

    return (
        <>
            <Header/>
            <main className="main mb-1">
                <div className="jumbotron text-center bg_top"
                     style={{backgroundImage: `url("${item.profileCoverPhoto}")`}}>
                    <div className="container">
                    </div>
                </div>
                <div className="container">
                    <div className="row row-cols-4">
                        <div className="col-12 mb-1 mb-sm-4 container-distance">
                            <div className="d-flex flex-row align-items-center mt-n64">
                                <RestaurantName item={item}/>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-5 order-md-2">
                            <div className="card mb-4 p-3 box-shadow">
                                <Form {...{item}}/>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-7 order-md-1">
                            <div className="card flex-md-row mb-4 box-shadow">
                                <ItemDetails {...{item}}/>
                            </div>
                        </div>
                    </div>
                    <div className="pt-0 pb-3">
                        <GoogleMap {...{item}}/>
                    </div>
                </div>
            </main>
            <Footer/>
        </>
    );
}
export default PageBridadosBogota;