import React  from 'react';
import { Switch, Route } from "react-router-dom";
import PageList from "../pages/p/index";
import PageBridadosBogota from "../pages/personalizedPages/bridadosBogota"
import PageTortelli from "../pages/personalizedPages/tortelli"
import PageCrepes from "../pages/personalizedPages/crepes"
import * as path from "./paths";

function Routes () {

    const principalPage = () => {
        window.location.href = 'https://myappointment.dev/';
        return null;
    }

    return (
        <Switch>
            <Route path={path.HomePath} exact component={principalPage}/>
            <Route path={path.PageBridados} exact component={PageBridadosBogota} />
            <Route path={path.PageTortelli} exact component={PageTortelli} />
            <Route path={path.PageCrepes} exact component={PageCrepes} />
            <Route path={path.PagePath} exact component={PageList} />
            <Route path='*' component={principalPage} />
        </Switch>
)};

export default Routes;