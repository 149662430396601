import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    '@global': {
        ul: {
            margin: 0,
            padding: 0,
            listStyle: 'none',
        },
    },
    footer: {
        fontSize: 16,
        padding: theme.spacing(6, 0),
        margin: theme.spacing(6, 'auto'),
    },
    separator: {
        height: '2px',
        opacity: '0.2',
        backgroundImage: `linear-gradient(to left, 
                            ${theme.palette.primary.main}E6, 
                            ${theme.palette.primary.light}CC, 
                            ${theme.palette.secondary.light}CC, 
                            ${theme.palette.secondary.main}E6)`,
    },
    spanFooter: {
        padding: theme.spacing(0, 0.8),
    },
    divider_left: {
        borderLeft: `2px solid ${theme.palette.divider}`,
        padding: theme.spacing(0, 0.8),
    },
    avatar: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.secondary.main,
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        }
    },
    scrollTop: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    appBar: {
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    toolbar: {
        flexWrap: 'wrap',
    },
    toolbarTitle: {
        flexGrow: 1,
    },
    link: {
        display: 'inline',
        margin: theme.spacing(1, 1.5),
        color: theme.palette.secondary.main,
        cursor: 'pointer',
        '&:hover': {
            color: theme.palette.primary.main,
        },
    },
    divider: {
        borderLeft: `2px solid ${theme.palette.divider}`,
        padding: theme.spacing(2, 2),
        marginLeft: theme.spacing(2),
    },
    outlineWhiteBtn: {
        borderRadius: '30px',
        color: theme.palette.common.white,
        borderColor: theme.palette.secondary.main,
        backgroundColor: theme.palette.secondary.main,
        margin: theme.spacing(0, 0.5),
        transition: 'all .25s ease-in-out',
        '&:hover': {
            color: theme.palette.common.white,
            borderColor: theme.palette.primary.main,
            backgroundColor: theme.palette.primary.main,
            transform: 'scale(1) translateY(-4px) translateZ(20px)',
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        hide: {
            display: 'none',
        },

    },



    [theme.breakpoints.down('sm')]: {
        //  Breakpoint Style
        headerDisplay: {
            display: 'grid',
        },
    },

    [theme.breakpoints.down('md')]: {
        //  Breakpoint Style
        link: {
            display: 'list-item',
        },
        toolbarTitle: {
            maxWidth: '83%'
        },
    },
}));

export { useStyles }
