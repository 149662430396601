import { useState, useEffect } from "react";
import {db} from "../services/firebase";

const GetDataFromId = (id) => {
    const [item, setItem] = useState(null);
    useEffect( () => {
        (async function () {
            const itemRef = db.collection( 'merchant_data' );
            const item = await itemRef.doc( id ).get();
            if (item.empty) {
                console.log( 'No matching documents.' )
                setTimeout( () => window.location.href = 'https://myappointment.dev/', 2000 );
                return;
            }
            const data = []
            data.push( {...item.data(), id: item.id} )
            setItem( data[0] );
        })();
    }, [id] );
    return item
};
export default GetDataFromId;