import React, { useState } from 'react';

function Step1( props ) {

    const [values, setValues] = useState();

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({ ...values, [name]: value });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        props.checkData(values)
        await props.setPage(1);
    }

    return (
        <form onSubmit={handleSubmit} name="myAppoinment">
            <h3>Reserve Aqui</h3>
            <div className="form-group">
                <span htmlFor="date">Fecha de reserva</span>
                <input
                    type="date"
                    className="form-control"
                    placeholder=" "
                    name="date"
                    required
                    onChange={handleChange}
                />
            </div>
            <div className="form-group">
                <span htmlFor="capacity">Numero de personas</span>
                <select
                    id="capacity"
                    className="form-control"
                    placeholder=" "
                    name="capacity"
                    required
                    onChange={handleChange}
                >
                    <option value="">Seleccione</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                </select>
            </div>
            <button className="btn btn-primary btn-block my-3" type="submit">Siguiente</button>
        </form>
    );
};

export default Step1;