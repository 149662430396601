const Preloader = () => {
    return (
        <div className="d-flex justify-content-center vh-100">
            <div className="align-self-center">
                <div className="col">
                    <div className="spinner-border text-danger" role="status">
                    </div>
                    <span className="text-danger preloader">...Loading</span>
                </div>
            </div>
        </div>
    );
}
export default Preloader;