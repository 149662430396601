import React from "react";
import {BrowserRouter} from "react-router-dom"
import Routes from './navigation/routes'
import 'react-toastify/dist/ReactToastify.css';

function App() {

  return (
      <React.Fragment>
        <BrowserRouter>
          <Routes/>
        </BrowserRouter>
      </React.Fragment>
  );
}

export default App;