import React from 'react';
import clsx from 'clsx';
import {
    AppBar,
    Toolbar,
    Button,
    Typography,
    Drawer,
    List,
} from '@material-ui/core';

import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Hidden from '@material-ui/core/Hidden';
import Logo from '../common/logo'
import {useStyles} from '../../assets/css/useStyles'



export default function Header(props) {

    const classes = useStyles();


    const [open, setOpen] = React.useState( );

    const toggleDrawer = (open) => (event) => {
        event.preventDefault();
        setOpen(open);
    };

    function MenuItems() {

        const classes = useStyles();

        return (
            <List className={classes.fullList} onClick={toggleDrawer( false )} onKeyDown={toggleDrawer( false )}>

            </List>
        );
    }

    return (
        <header>
            <AppBar position="static" color="default" elevation={0} className={classes.appBar} id="top">
                <Toolbar className={classes.toolbar} >
                    <Typography xs={8} variant="h6" className={classes.toolbarTitle}>
                        <Logo/>
                    </Typography>
                    <Hidden mdUp>
                        <IconButton xs={4} color="inherit" onClick={toggleDrawer(true)} className={clsx( classes.menuButton, open && classes.hide )}>
                            <MenuIcon/>
                        </IconButton>
                        <Drawer open={open} onClose={toggleDrawer(false)} >
                            <MenuItems/>
                        </Drawer>
                    </Hidden>
                    <Hidden mdDown>
                        <nav className={classes.headerDisplay}>
                            <MenuItems/>
                        </nav>
                        <Hidden smDown>
                            <div className={classes.divider}/>
                            <Button href="https://app.myappointment.dev/signup" variant="outlined"
                                    className={classes.outlineWhiteBtn}>
                                Sign Up
                            </Button>
                            <Button href="https://app.myappointment.dev" variant="outlined"
                                    className={classes.outlineWhiteBtn}>
                                login
                            </Button>
                        </Hidden>
                    </Hidden>

                </Toolbar>
            </AppBar>
        </header>
    );
}
