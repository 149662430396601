import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faClock, faPhoneVolume, faExternalLinkAlt} from '@fortawesome/free-solid-svg-icons'

function ItemDetails({item}) {

    return (
        <>
            <div className="card-body d-flex flex-column align-items-start">
                <ul className="fa-ul">
                    {item.schedule === "" ? "" :
                    <li className="d-flex align-items-center mb-3">
                        <span className="fa-li"><FontAwesomeIcon className="text-primary" icon={faClock} size="lg" fixedWidth/></span>
                        <span className="mx-1"><strong>Horario:</strong> {item.schedule}</span>
                    </li>}
                    {item.telephone === "" ? "":
                    <li className="d-flex align-items-center mb-3">
                        <span className="fa-li"><FontAwesomeIcon className="text-primary" icon={faPhoneVolume} size="lg" transform={{ rotate: -42 }} fixedWidth/></span>
                        <span className="mx-1"><strong>Telefono:</strong> {item.telephone}</span>
                    </li>}
                    {item.url === "" ? "" :
                    <li className="d-flex align-items-center mb-3">
                        <span className="fa-li"><FontAwesomeIcon className="text-primary" icon={faExternalLinkAlt} size="lg" fixedWidth/></span>
                        <span className="mx-1"><strong>Pagina Web:</strong> <a
                            href={`https://${item.url}`} target='_blank' rel="noreferrer">{item.url}</a></span>
                    </li>}
                    {item.urlMenu === "" ? "" :
                    <li className="d-flex align-items-center mb-3">
                        <a href={item.urlMenu} target='_blank' rel="noreferrer">
                            <span className="fa-li"><FontAwesomeIcon className="text-primary" icon={faExternalLinkAlt} size="lg" fixedWidth/></span>
                            <span className="mx-1"><strong>Menu</strong></span>
                        </a>
                    </li>}
                </ul>
                {item.description === "" ? "" :
                <span>
                    {item.description}
                </span>}
            </div>
        </>
    );
}

export default ItemDetails;