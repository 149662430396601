import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics';

// Your web bookform's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
firebase.initializeApp ({
    apiKey: "AIzaSyCrGOlT7ET4qEHutTwWG_D7O9nFormWP0w",
    authDomain: "myappointment-dev.firebaseapp.com",
    projectId: "myappointment-dev",
    storageBucket: "myappointment-dev.appspot.com",
    messagingSenderId: "656230455577",
    appId: "1:656230455577:web:75777587523b6a6b574d88",
    measurementId: "G-Y1266F9E3S"
});
// Initialize Firebase
export const db = firebase.firestore();
export const analytics = firebase.analytics();
export const auth = firebase.auth();
