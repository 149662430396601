import React, { useState } from 'react';

function Step1( props ) {

    const [city, setCity] = useState("Bogota")
    const handleSetCity = e => {
        const {value} = e.target;
        e.preventDefault();
        setCity(value)
    }

    const [values, setValues] = useState();
    const handleChange = e => {
        const {name, value} = e.target;
        setValues( {...values, [name]: value} );
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        props.checkData(values)
        await props.setPage(1);
    }
    
    const restaurant = props.places[city]

    return (
        <form onSubmit={handleSubmit} name="myAppoinment">
            <div className="form-group">
                <span htmlFor="capacity">Selecciona la Ciudad</span>
                <select
                    id="city"
                    className="form-control"
                    placeholder=" "
                    name="city"
                    required
                    onChange={handleSetCity}
                >
                    <option value="Bogota">Bogota</option>
                    <option value="Barranquilla">Barranquilla</option>
                    <option value="Medellin">Medellin</option>
                    <option value="Bucaramanga">Bucaramanga</option>
                    <option value="Cali">Cali</option>
                    <option value="Cartagena">Cartagena</option>
                    <option value="Pereira">Pereira</option>
                    <option value="Santa Marta">Santa Marta</option>
                    <option value="Villavicencio">Villavicencio</option>
                    <option value="Manizales">Manizales</option>
                </select>

            </div>
            <div className="form-group">
                <span htmlFor="capacity">Selecciona el restaurante</span>
                <select
                    id="userId"
                    className="form-control"
                    placeholder=" "
                    name="userId"
                    required
                    onChange={handleChange}
                >
                    {restaurant.map((option, index) => (
                        <option key={index} value={option.uid}>
                            {option.name}
                        </option>
                    ))}
                </select>

            </div>
            <div className="form-group">
                <span htmlFor="date">Fecha de reserva</span>
                <input
                    type="date"
                    className="form-control"
                    placeholder=" "
                    name="date"
                    required
                    onChange={handleChange}
                />
            </div>
            <div className="form-group">
                <span htmlFor="capacity">Numero de personas</span>
                <select
                    id="capacity"
                    className="form-control"
                    placeholder=" "
                    name="capacity"
                    required
                    onChange={handleChange}
                >
                    <option value="">Seleccione</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                </select>
            </div>
            <button className="btn btn-primary btn-block my-3" type="submit">Siguiente</button>
        </form>
    );
};

export default Step1;