import React, {useState} from 'react';
import Step1 from './step_1'
import Step2 from '../../form/step_2'
import Step3 from '../../form/step_3'
import ThankYou from "../../form/thankyou"
import {db} from '../../../services/firebase'

const Form = ({item}) => {

    const userId = item.id;
    const timestamp = Date.now();
    const merchantDB = db.collection( 'merchant_data' );
    const bookingsDB = db.collection( 'Bookings' );

    const initialStateValues = {
        date: '',
        time: '',
        capacity: '',
        name: '',
        email: '',
        phone: '',
        userId: userId,
        create: timestamp,
    };

    const [selection, setSelection] = useState( initialStateValues )
    const [button, setButton] = useState( 'Siguiente' )
    const [times, setTimes] = useState( [] )

    const checkData = async (linkObject) => {

        setSelection( {...selection, ...linkObject} )
        const checkUser = linkObject.userId
        const selectDay = linkObject.date
        const dSelectDay = new Date( `${selectDay}T00:00:00` )
        const getToday = new Date()
        const weekDay = dSelectDay.getDay()
        const selectDayLocal = dSelectDay.toLocaleString( 'en-US', {timeZone: 'America/Bogota'} ).substring( 0, 10 )
        const today = getToday.toLocaleString( 'en-US', {timeZone: 'America/Bogota'} ).substring( 0, 10 )
        const localHour = getToday.toLocaleTimeString( 'en-US', {hour12: false} )
        const getMerchantData = await merchantDB.doc( checkUser ).get();
        const merchantData = getMerchantData.data()
        const times = merchantData.week[weekDay]
        const maxCapacity = merchantData.capacity

        await bookingsDB.where( "userId", "==", checkUser )
            .where( "date", "==", selectDay )
            .onSnapshot( (QuerySnapshot) => {
                const docs = [];
                QuerySnapshot.forEach( doc => {
                    docs.push( {...doc.data(), id: doc.id} )
                } );
                times.forEach( (hour) => {
                    docs.push( {time: hour, capacity: 0 })
                })
                const result = [];
                docs.forEach( function (book) {
                    const x = Number( book.capacity )
                    if (!this[book.time] && !this[x]) {
                        this[book.time] = {time: book.time, capacity: 0};
                        result.push( this[book.time] );
                    }
                    this[book.time].capacity += x;
                }, Object.create( null ) );

                const completedBook = result
                    .filter( reserved => reserved.capacity < maxCapacity )
                    .filter( reserved => reserved.time !== "" )
                    .filter( reserved => reserved.time !== undefined )
                    .map( time => time.time)
                    .sort()

                if (selectDayLocal === today) {
                    const filteredTimes = completedBook
                        .filter( hour => hour >localHour)
                    if (filteredTimes.length === 0) {
                        setButton( 'Cancel' )
                    }
                    setTimes( filteredTimes );
                } else {
                    setTimes( completedBook );
                    setButton( 'Siguiente' )
                }
                return null
            } )

    }

    const setHour = (linkObject) => {
        setSelection( {...selection, ...linkObject} )
    }

    const setPersonalData = (linkObject) => {
        setSelection( {...selection, ...linkObject} )
    }

    const sendFormData = async (e) => {
        await db.collection( 'Bookings' ).doc().set( selection );
        // console.log(selection)
    }

    const [page, setPage] = useState( 0 );

    return (
        <React.Fragment>
            {page === 0 ? <Step1 setPage={setPage} checkData={checkData}/> : null}
            {page === 1 ? <Step2 setPage={setPage} times={times} setHour={setHour} button={button}/> : null}
            {page === 2 ? <Step3 setPage={setPage} setPersonalData={setPersonalData}/> : null}
            {page === 3 ? <ThankYou sendFormData={sendFormData}/> : null}
        </React.Fragment>
    );
};

export default Form;



