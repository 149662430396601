import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/index.css'
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css'
import {
    ThemeProvider,
    createMuiTheme,
} from '@material-ui/core';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#FF0000',
            light: '#FF6464',
            dark: '#9C0000',
        },
        secondary: {
            main: '#1240AB',
            light: '#5877C0',
            dark: '#082568',
        },
    },
    typography: {
        fontFamily: [
            'Roboto',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Montserrat',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },

});


ReactDOM.render(
  <React.StrictMode>
      <ThemeProvider theme={theme}>
          <App />
      </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
